html,
body,
#root {
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
}

.App {
  font-family: sans-serif;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
  background-color: #999;
}
.map-wrapper {
  padding: 8px;
  flex: 1; /* same as flex: 1 1 auto; */

  display: flex;
  flex-direction: row;

  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}

.leaflet-container {
  border: 1px solid #555;
  background: #eee;

  overflow: hidden;
  flex: 1;
}
